@font-face {
    font-family: SofiaPro;
    src: url('./fonts/SofiaPro/SofiaProLight.otf');
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: SofiaPro;
    src: url('./fonts/SofiaPro/SofiaProLightItalic.otf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: SofiaPro;
    src: url('./fonts/SofiaPro/SofiaProRegular.otf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: SofiaPro;
    src: url('./fonts/SofiaPro/SofiaProRegularItalic.otf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: SofiaPro;
    src: url('./fonts/SofiaPro/SofiaProMedium.otf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-ExtraLightNeue.otf');
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-ExtraLightNeueItalic.otf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-LightNeue.otf');
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-LightNeueItalic.otf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-RegularNeue.otf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-RegularNeueItalic.otf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-MediumNeue.otf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-MediumNeueItalic.otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-SemiBoldNeue.otf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-SemiBoldNeueItalic.otf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-BoldNeue.otf');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-BoldNeueItalic.otf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-ExtraBoldNeue.otf');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-ExtraBoldNeueItalic.otf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-BlackNeue.otf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue;
    src: url('./fonts/CervoNeueNeue/CervoNeue-BlackNeueItalic.otf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: CervoNeue-Neue-Thin;
    src: url('./fonts/CervoNeueNeue/CervoNeue-ThinNeue.otf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: CervoNeue-Neue-Thin;
    src: url('./fonts/CervoNeueNeue/CervoNeue-ThinNeueItalic.otf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Chromosome;
    src: url('./fonts/Chromosome/Chromosome-Heavy.otf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: ABChanelPBL;
    src: url('./fonts/ABChanel-PB/ABChanel-PB-Regular-L.otf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: ABChanelPBM;
    src: url('./fonts/ABChanel-PB/ABChanel-PB-Regular-M.otf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: ABChanelPBS;
    src: url('./fonts/ABChanel-PB/ABChanel-PB-Regular-S.otf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: ABChanelPBL;
    src: url('./fonts/ABChanel-PB/ABChanel-PB-SemiBold-L.otf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: ABChanelPBM;
    src: url('./fonts/ABChanel-PB/ABChanel-PB-SemiBold-M.otf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: ABChanelPBS;
    src: url('./fonts/ABChanel-PB/ABChanel-PB-SemiBold-S.otf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: ABChanelCorpo;
    src: url('./fonts/ABChanel/ABChanelCorpo-Reg.ttf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: ABChanelCorpo;
    src: url('./fonts/ABChanel/ABChanelCorpo-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: ABChanel;
    src: url('./fonts/ABChanel/ABChanelCorpo-Reg.ttf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: ABChanel;
    src: url('./fonts/ABChanel/ABChanelCorpo-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}
